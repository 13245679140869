var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('div',[_c('filter-header',{attrs:{"content":_vm.content,"can-order-by-name":_vm.canOrderByName},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderApplicants}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"page-number":_vm.filterData.page,"items":_vm.getData,"fields":_vm.fields,"custimized-items":[
              { name: 'date' },
              { name: 'vendor' },
              { name: 'service_type' },
              { name: 'action' },
              { name: 'price' },
              { name: 'status' },
              { name: 'rate' } ]},scopedSlots:_vm._u([{key:"date",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.formatDate(data.item.date)))])])]}},{key:"vendor",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.vendor}})]}},{key:"service_type",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.service_type}})]}},{key:"price",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":Number(data.item.price)+Number(data.item.service_fee),"dis-price":Number(data.item.price)+Number(data.item.service_fee)-Number(data.item.dis_price)}})]}},{key:"rate",fn:function(ref){
            var data = ref.data;
return [_c('customer-rate',{attrs:{"rate":data.item.rate}})]}},{key:"status",fn:function(ref){
            var data = ref.data;
return [_c('custom-badge',{attrs:{"text":data.item.status_name,"color":data.item.status_color}})]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.toToDetail(data.item.id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary permission-show-detail"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,1369020202)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalData)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalData.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }